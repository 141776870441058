import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    // { title: 'My Profile', url: '/profile', icon: 'Person' },
    // { title: 'Saved', url: '/saved', icon: 'bookmark' },
    // { title: 'Cuentas', url: '/cuentas', icon: 'pricetags' },
    // { title: 'Supermarket', url: '/supermarket', icon: 'cart' },
    // { title: 'Posts', url: '/notas/notas', icon: 'create' },
  ];
  public appSettings = [
    // { title: 'Settings', url: '/settings', icon: 'settings' },
    // { title: 'Guide me', url: '/settings', icon: 'information' },
    // { title: 'Help me', url: '/settings', icon: 'help' },
  ];

  constructor(private menuCtrl: MenuController) {}
  toggleMenu() {
    this.menuCtrl.toggle();
  }
}
