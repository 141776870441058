import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import firebase from 'firebase/app';
firebase.initializeApp({
  apiKey: 'AIzaSyB3VGPcP_LKSgJ4Ikk1d1EAnvoQE33kR2U',
  authDomain: 'garciia.firebaseapp.com',
  projectId: 'garciia',
  storageBucket: 'garciia.appspot.com',
  messagingSenderId: '845327733531',
  appId: '1:845327733531:web:c669420a78500c93b479be',
  measurementId: 'G-Y8DG7C3G4M',
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
