import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./all-pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'notas',
    loadChildren: () =>
      import('./app-pages/appNotas/notas.module').then(
        (m) => m.NotasPageModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./all-pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./all-pages/my-profile/my-profile.module').then(
        (m) => m.MyProfilePageModule
      ),
  },
  {
    path: 'saved',
    loadChildren: () =>
      import('./all-pages/saved-posts/saved-posts.module').then(
        (m) => m.SavedPostsPageModule
      ),
  },
  {
    path: 'cuentas',
    loadChildren: () =>
      import('./all-pages/cuentas/cuentas.module').then(
        (m) => m.CuentasPageModule
      ),
  },
  {
    path: 'new',
    loadChildren: () =>
      import('./all-pages/cuentas/new/new.module').then((m) => m.NewPageModule),
  },
  {
    path: 'supermarket',
    loadChildren: () =>
      import('./all-pages/supermarket/supermarket.module').then(
        (m) => m.SupermarketPageModule
      ),
  },
  {
    path: 'newProduct',
    loadChildren: () =>
      import('./all-pages/supermarket/new-product/new-product.module').then(
        (m) => m.NewProductPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
